import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/Layout.vue'),
	redirect: '/index',
	meta: {
	  title: '主页'
	},
	children: [
		{
		  path: '/index',
		  component: () => import('../views/index/index.vue'),
		  name: 'Index',
		  meta: {
			title: '工作台'
		  },
		  children: null
		},
		{
		  path: '/setting',
		  component: () => import('../views/system/setting/index.vue'),
		  name: 'Setting',
		  meta: {
			title: '系统设置'
		  },
		  children: null
		},
		{
		  path: '/admin_role',
		  component: () => import('../views/system/admin_role/index.vue'),
		  name: 'AdminRole',
		  meta: {
			title: '角色管理'
		  },
		  children: null
		},
		{
		  path: '/admin',
		  component: () => import('../views/system/admin/index.vue'),
		  name: 'Admin',
		  meta: {
			title: '人员列表'
		  },
		  children: null
		},
		{
		  path: '/operation',
		  component: () => import('../views/system/operation/index.vue'),
		  name: 'Operation',
		  meta: {
			title: '权限管理'
		  },
		  children: null
		},
		{
		  path: '/operation_log',
		  component: () => import('../views/system/operation_log/index.vue'),
		  name: 'OperationLog',
		  meta: {
			title: '系统日志'
		  },
		  children: null
		},
		{
		  path: '/images',
		  component: () => import('../views/system/images/index.vue'),
		  name: 'Images',
		  meta: {
			title: '图片管理'
		  },
		  children: null
		},
		{
		  path: '/model',
		  component: () => import('../views/operate/model/index.vue'),
		  name: 'Images',
		  meta: {
			title: '型号管理'
		  },
		  children: null
		},
		{
		  path: '/project',
		  component: () => import('../views/operate/project/index.vue'),
		  name: 'Project',
		  meta: {
			title: '项目管理'
		  },
		  children: null
		},
		{
		  path: '/upgrade',
		  component: () => import('../views/upgrade/upgrade/index.vue'),
		  name: 'Upgrade',
		  meta: {
			title: '更新管理'
		  },
		  children: null
		}
	],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	// 获取token
	let token = Vue.prototype.VueCookies.get("token")
	if(token){ // 已登录
	    // 防止重复登录
		if(to.path === '/login'){
			// Vue.prototype.$message.error('请不要重复登录');
			return next({path:from.path ? from.path : '/'})
		}
		//其他验证
		// 验证权限
		// let rules = window.sessionStorage.getItem('rules')
		// rules = rules ? JSON.parse(rules) : []

		// let index = rules.findIndex(item=>{
		// 	return  item.rule_id > 0 && item.desc === to.name
		// })
		// if(index === -1){
		// 	Vue.prototype.$message.error('你没有权限')
		// 	return next({name:from.name ? from.name : '/'})
		// }
		
	    next();
	}else{
		// 跳过登录页验证
		if(to.path === '/login'){
			return next();
		}
		// 未登录
		// Vue.prototype.$message.error('请先登录');
		next({ path:'/login' })
	}
})

export default router
